@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;700;900&display=swap');
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';


* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Manrope', sans-serif;
}

hr {
  width: 100%;
}

/* Cabeçalho */

.header {
  display: flex;
  height: 100vh;
  align-items: center;
  padding: 20px;
  background-color: #191919;
}

.header-left,
.header-right {
  flex: 1;
  max-width: 50%;
}

.header-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
}

.header-left h1 {
  color: #F3F3F3;
  font-size: 72px;
  font-weight: 900;
  line-height: 1.2;
  letter-spacing: -2.88px;
  margin: 0;
}

.custom-font {
  font-family: 'Aldrich', sans-serif;
  font-size: inherit;
  /* Mantém o mesmo tamanho de fonte do h1 */
  font-weight: inherit;
}


.header-left p {
  color: #aaa;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 24px;
}

.header button {
  background-color: #fff;
  color: #000;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
  width: 250px;
}

.header button:hover {
  background-color: #bbbbbb;
}

.header-right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-right img {
  width: 100%;
  height: 100vh;
  margin-right: -38px;
}

/* Serviços */

.servicos {
  display: flex;
  width: 100%;
  padding: 120px;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
}

.servicos h2 {
  color: #191919;
  font-size: 72px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: -2.88px;
}

.servicos h3 {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.96px;
  opacity: 0.6;
}

.card {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 4px;
  align-self: stretch;
  flex-wrap: wrap;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.servicos-card {
  display: flex;
  height: 180px;
  min-width: 400px;
  max-width: 610px;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  border-radius: 10px;
  background: #e8e7e7;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

.servicos-card.visible {
  opacity: 1;
  transform: translateY(0);
  animation: fadeInUp 2s ease forwards;
}

.card-titulo h1 {
  color: #000;
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: -1.6px;
}

/* Integrantes */

.team {
  display: flex;
  width: 100%;
  padding: 120px;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
}

.team-titulo h2 {
  color: #191919;
  font-size: 72px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: -2.88px;
}

.team-titulo h3 {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.96px;
  opacity: 0.5;
  margin-top: 35px;
}

.team-cards p {
  margin-top: 10px;
  text-align: justify;
  font-size: smaller;
}

.team-cards {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 12px 189px;
  flex-wrap: wrap;
}

.team-cards img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 20px;
}

.cards img {
  width: 320px;
  height: 280px;
  overflow: hidden;
  position: relative;
}

.cards {
  display: flex;
  min-width: 340px;
  max-width: 360px;
  height: 480px;
  padding: 20px 20px 40px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  border-radius: 20px;
  background: #e8e7e7;
}

.cards h2{
  text-align: center;
}

/* Contato */

.contato {
  padding: 50px;
  background-color: #fff;
  text-align: left;
  margin-top: 100px;
  max-width: 100%;
}

.contato-titulo h1 {
  color: #191919;
  font-size: 72px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: -2.88px;
}

.contato-card {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 40px;
  margin-top: 24px;
}

.contato-info {
  flex: 1;
  margin-right: 20px;
}

.contato-info h3 {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.96px;
  align-self: stretch;
}

.contato-info p {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.96px;
  align-self: stretch;
}

.contato-info a {
  text-decoration: none;
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.96px;
  align-self: stretch;;
}

.contato button {
  background-color: #000;
  color: #fff;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
  margin-bottom: 150px;
}

.contato button:hover {
  background-color: #333;
}

/* Rodape */

.footer {
  width: 100%;
  padding: 20px;
  background-color: #000000;
  text-align: center;
  border-top: 1px solid #e0e0e0;
  /* Cria a linha de rodapé */
  position: relative;
  bottom: 0;
  left: 0;
}

.footer p {
  margin: 0;
  font-size: 14px;
  color: #666;
}


@media only screen and (max-width: 480px) {
  /* Ajustes gerais */

  body {
    background-color: #FFF;
    max-width: 100%;
  }

  .header {
    flex-direction: column;
    height: auto;
    padding: 10px;
  }

  .header-left,
  .header-right {
    max-width: 100%;
    text-align: center;
  }

  .header-left h1 {
    font-size: 48px;
    letter-spacing: -1.92px;
  }

  .header-left p {
    font-size: 18px;
    margin-top: 16px;
  }

  .header-right img {
    height: auto;
    width: 100%;
    margin-right: 0;
  }

  .header button {
    width: auto;
  }

  .servicos,
  .team {
    padding: 60px 20px;
  }

  .servicos h2,
  .team-titulo h2 {
    font-size: 48px;
    letter-spacing: -1.92px;
  }

  .servicos h3 {
    text-align: justify;
  }

  .servicos-card,
  .cards {
    min-width: 100%;
    max-width: 100%;
    padding: 20px;
  }

  .team-cards {
    gap: 12px;
    justify-content: center;
  }

  .team-cards img {
    height: 200px;
    width: 100%;
    border-radius: 20px;
    background: #FFF;
  }

  .cards p {
    font-size: smaller;
  }

  .cards h2 {
    font-size: 22px;
    margin-top: 5px;
  }

  .team h3 {
    text-align: justify;
  }

  .contato {
    padding: 30px;
  }

  .contato-titulo h1 {
    font-size: 48px;
    letter-spacing: -1.92px;
  }

  .contato-card {
    flex-direction: column;
    align-items: center;
  }

  .contato-info h3,
  .contato-info p {
    font-size: 18px;
    margin-top: 10px;
  }

  .contato-info {
    text-align: center;
  }

  .contato button {
    width: 100%;
  }

  .footer {
    padding: 15px;
    width: auto;
  }

  .footer p {
    font-size: 12px;
  }
}